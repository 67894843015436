@import "variables";
@import "mixins";

nav {
    background-color: #242c3d;

    ul li.active {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

button.cta.fetch-images-cta.right {
    margin-top: 0;
    width: 150px;
}

button.cta.fetch-images-cta.upload-fields {
    float: left;
    width: 100px;
    margin-top: 17px;
    padding: 5px;
    margin-left: 7px;
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime],
input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea, .w-input, .w-select, select.show {
    display: block;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.428571429;
    color: #333333;
    vertical-align: middle;
    background-color: #FAFAFA;
    border: 1px solid #b2bac3;
    height: 42px;
    border-radius: 5px;
    box-sizing: border-box;
}

#upload_fields {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    float: left;
}

input.search-input {
    margin-top: 20px;
    width: 90%;
    float: left;
}

button.search-button {
    margin-top: 22px;
    float: right;
}

.field-coordinates-display {
    height: 50px;
    overflow: hidden;
}

.farm-fields-row {
    position: relative;
    .delete-farm-field {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        right: -3px;
        top: 14px;
        i {
            color: #fff;
            font-size: 18px;
            margin: -1px;
        }
    }
}

.farmpin-logo {
    display: inline-block;
    height: 35px;
    width: auto;
    vertical-align: middle;
    margin-bottom: 5px;
}

.customer-row:hover {
    cursor: pointer;
}

.mission {
    padding-bottom: 85px;

    textarea {
        height: 88px;
    }

    .header-section {
        padding: 10px 20px;
        margin-bottom: 30px;
        .farmpin-logo {
            width: 20px;
            height: auto;
        }
        .farmpin-wordmark {
            top: 10px;
            font-size: 21px;
        }
    }

    .tile {
        h4 {
            margin-top: 0;
            margin-bottom: 25px;
        }
        .row:last-child {
            margin-bottom: 0;
        }
    }

    .farm-deets {
        @include visible-scrollbar; /* Don't hide the coordinates scrollbar when not in use */
        .coordinates {
            padding: 12px;
            max-height: 400px;
            overflow-y: scroll;
            pre {
                margin: 0;
            }
        }
        a.edit-coords {
            color: $call-to-action-colour;
            text-decoration: none;
            text-align: right;
            display: block;
            margin-bottom: 3px;
            font-size: 0.9em;
            img {
                margin-right: 4px;
                margin-top: -3px;
            }
        }
    }

    .checkbox-actions {
        margin-top: 14px;
    }

    .cta {
        width: 100%;
        margin-top: 20px;
    }

    .handover-actions {
        margin-top: 35px;
        .mission {
            padding-bottom: 0;
        }
        .row {
            margin-bottom: 0;
        }
        .header-section {
            display: none;
        }
        .container {
            width: 100%;
            padding: 0;
        }
        .tile {
            margin: 0;
            @include box-shadow-custom(none);
        }
        input:last-child {
            margin-bottom: 0;
        }
        .save-mission {
            display: none;
        }
    }

    .footer {
        background-color: #1A3146;
        color: white;
        overflow: auto;
        padding: 10px 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        .mission-complete {
            margin-top: 8px;
            float: left;
            [type="checkbox"] + label {
                font-size: 1.2em;
                font-weight: 200;
                line-height: 31px;
                padding-left: 40px;
                &:before {
                    width: 24px;
                    height: 24px;
                }
            }
            [type="checkbox"]:checked + label:before {
                width: 16px;
                height: 28px;
            }
        }
        .save-mission {
            float: right;
            .cta {
                margin-top: 0;
                width: 168px;
            }
        }
    }

    .pilot, .expert, .processor {
        .save-mission {
            overflow: auto;
            .cta {
                margin-top: 0;
                width: auto;
                float: right;
            }
        }
    }

    div.row.file-uploader-parent {
        padding-top: 0px;
    }

    .file-field {
        width: 460px;
        margin: auto;
        vertical-align: middle;
        text-align: center;
        .btn,
        .btn:hover {

            margin-left: auto;
            height: 160px;
            max-width: 300px;
            text-align: center;
            border: 1px solid #d6d9db;
            background: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -ms-box-shadow: none;
            box-shadow: none;
            padding-top: 38px;
            padding-left: 7px;
            padding-right: 10px;
            margin-bottom: 15px;
            width: 100%;
            margin-right: auto;
            float: none;
            width: 100%;

            span.dropdown-hint {
                display: block;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #231f20;
                text-transform: none;
                display: block;
                margin-top: -10px;
            }

            .upload-icon {
                @include border-radius(50%);
                @include box-shadow-custom(5);
                margin: 6px 10px 0 0;
                background-color: $call-to-action-colour;
                width: 28px;
                height: 28px;
                float: left;
                img {
                    vertical-align: top;
                    margin-left: 7px;
                    margin-top: 6px;
                }
            }
            span {
                color: $call-to-action-colour;
            }

        }

        h4.file-suggestion {
            margin: 0;
            margin-top: -25px;
            font-family: Lato;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #919395;
        }

        .file-path-wrapper {
            padding-left: 0;
            display: block;
            clear: both;
            position: relative;
            overflow: visible;
            input.file-path,
            input.file-path:focus {
                box-shadow: none;
                border: 1px solid $dark-gray;
                background-color: transparent;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 30px;
            }

        }

    }

    .progress {
        background-color: $light-gray;
    }

    .progress .indeterminate {
        background-color: $call-to-action-colour;
    }

    .hidden {
        visibility: hidden;
    }
    .mission-map-row-item.hidden {
        display: none;
    }

    .datepicker.map-image-datepicker {
        width: 150px !important;
        margin-top: 6px;
        margin-left: auto;
        margin-right: auto;
    }

    div.map-image-type-parent {

        padding: 4px;
        margin: 10px auto;
        height: 46px;
        width: 224px;
        border-radius: 4px;
        background-color: #FFFFFF;
        border: 1px solid #dadada;

        .map-image-type {
            background-color: #FFFFFF;
            height: 36px;
            width: 107px;
            color: #142944;
            font-size: 14px;
            line-height: 21px;
            text-align: center;

        }

        .map-image-type.right-button {
            margin-right: 11px;
        }

        .map-image-type.left-button {
            margin-left: 11px;
        }

        .map-image-type.small {
            width: 60px;
        }

        .selected-map-image-type {

            border-radius: 3px;
            background-color: #142944;
            color: #FFFFFF;
            font-weight: bold;

        }

    }

    div.map-image-type-parent.small {
        width: 133px;
    }

}
